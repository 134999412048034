<template>
  <div class="ma-4">
    <v-form class="container"
            @submit.prevent="pesquisar"
    >
      <v-card>
        <v-card-title>Impressão de Boletim de Ocorrência</v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text>

          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete label="Selecione a Regional"
                              dense
                              v-model="form.regional"
                              :items="tela.regionais"
                              :loading="tela.carregando"
                              @change="carregaUnidades"
                              clearable
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete label="Selecione a Unidade"
                              dense
                              v-model="form.unidade"
                              :items="tela.unidades"
                              :loading="tela.carregando"
                              clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field label="Informe o nome da pessoa"
                            dense
                            v-model="form.nome_pessoa"
                            clearable></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field label="Informe o número do boletim"
                            dense
                            v-model="form.ocorrencia"
                            clearable></v-text-field>
            </v-col>
          </v-row>

          <v-row>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field label="Informe a data do registro"
                            type="date"
                            dense
                            v-model="form.dt_registro"
                            clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field label="Informe a data do fato"
                            type="date"
                            dense
                            v-model="form.dt_fato"
                            clearable></v-text-field>
            </v-col>
          </v-row>


        </v-card-text>


        <v-divider></v-divider>
        <v-card-actions class="pa-6">
          <v-btn color="primary" type="submit" :loading="tela.carregando">Pesquisar</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>

    <div class="container">
      <v-row justify="end" class="pa-4">
        <v-btn class="blue-grey text-none" dark
               target="_blank"
               :href="linkImpressao"
               >
<!--               @click="imprimir"-->
          Imprimir&nbsp;
          <v-icon>mdi-printer-settings</v-icon>
        </v-btn>
      </v-row>

      <v-data-table
          class="elevation-2"
          :loading="tabela.carregando"
          disable-sort
          show-select
          single-select="single-select"
          :headers="tabela.colunas"
          :items="tabela.dados"
          v-model="tabela.linhaSelecionada"
      ></v-data-table>

    </div>

  </div>
</template>

<script>

import api from '../../api'

export default {
  name: "consultaOcorrencia",
  computed: {
    linkImpressao() {
      if (this.tabela.linhaSelecionada.length > 0) {
        return `${process.env.VUE_APP_BASE}ocorrencia/impressao/${this.tabela.linhaSelecionada[0].num_boletim}`
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      tela: {
        regionais: [],
        unidades: [],
        carregando: true,
      },
      tabela: {
        linhaSelecionada: [],
        carregando: false,
        colunas: [
          {text: 'Nº Boletim', value: 'num_boletim'},
          {text: 'Data Registro', value: 'dt_registro'},
          {text: 'Data Fato', value: 'dt_fato'},
          {text: 'Nome Pessoa', value: 'nome_pessoa'},
          {text: 'Tipo', value: 'tipo'},
        ],
        dados: []
      },
      form: {
        nome_pessoa: '',
        regional: '',
        unidade: '',
        ocorrencia: '',
        dt_registro: '',
        dt_fato: '',
      },

    }
  },
  methods: {
    carregaUnidades() {
      this.tela.carregando = true;
      api.get(`/unidades/combo/${this.form.regional}`)
          .then((r) => {
            this.tela.unidades = r.data;
          })
          .finally(() => {
            this.tela.carregando = false;
          });
    },
    imprimir() {

      api.get(`/ocorrencia/impresao/${this.linhaSelecionada[0].num_boletim}`)
          .then(() => {

            let routeData = this.$router.resolve({
              name: 'impressao',
            });
            window.open(routeData.href);

          });
    },
    pesquisar() {
      this.tabela.carregando = true;
      api.get('/ocorrencia/grid', {
        params: this.form
      })
          .then((r) => {
            this.tabela.dados = r.data;
          })
          .finally(() => {
            this.tabela.carregando = false;
          });
    }
  },
  created() {
    api.get('/ocorrencia')
        .then((r) => {
          this.tela.regionais = r.data.regionais;
          this.tela.unidades = r.data.unidades;
        })
        .finally(() => {
          this.tela.carregando = false;
        })
  }
}
</script>

<style scoped>

</style>