<template>
  <v-app>
    <v-app-bar
        app
        color="grey darken-3"
        dark
        v-show="exibirBarra"
        class="ocultar"
    >
      <v-app-bar-title>
        <router-link to="/consulta-ocorrencia" style="text-decoration: none; color: white">ACERVO SISP</router-link>
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-btn to="/usuarios/senha" text>{{ $store.state.usuario.nome }}</v-btn>
      <v-btn
          text
          @click="sair"
      >
        <span>Sair</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-card color="grey darken-3"
            dark
            class="rounded-0 ocultar">
      <v-card-text>
        &copy; 2001 - DIME - Diretoria de Informática, Manutenção e Estatística -
        Divisão de Desenvolvimento de Sistemas
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>

import api from "./api";

export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    exibirBarra() {
      return this.$store.state.exibirBarra;
      // return this.$route.path !== '/';
    }
  },
  async created() {
    if (this.$store.state.usuario.nome) {
      this.$store.commit("EXIBIR_BARRA", true);

    } else {

      if (!(await this.infoUsuario())) {//tenta usar cookie atual para obter nome de usuário

        if (window.location.pathname !== '/') {//se não estiver na tela de login
          this.$router.push('/');//abre a tela de login
        }
      }
    }
  },

  methods: {
    async infoUsuario() {
      return await api.get('/usuarios/info')
          .then(r => {
            this.$store.commit("ATUALIZAR_USUARIO_NOME", r.data.nome);
            this.$store.commit("EXIBIR_BARRA", true);
            return true;
          })
          .catch(() => {
            return false;
          });
    },
    sair() {
      api.post('/logout')
          .finally(() => {
            this.$store.commit("LOGOUT");
            this.$router.push('/');
          })
    }
  }

};
</script>

<style>
@media print {
  .ocultar {
    display: none !important;
  }
}
</style>