<template>
  <v-main class="mt-16">
    <v-form @submit.prevent="logar">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>

            <v-card class="elevation-12">

              <v-toolbar dark color="grey darken-3">
                <v-img src="@/assets/policia.png"
                       max-width="100"
                ></v-img>

                <v-toolbar-title><b>ACERVO SISP</b></v-toolbar-title>

              </v-toolbar>

              <v-card-text>

                <v-text-field
                    class="mt-4"
                    prepend-icon="mdi-account"
                    name="login"
                    label="E-mail"
                    type="text"
                    v-model="form.email"
                    :rules="[rules.required, rules.email]"
                ></v-text-field>

                <v-text-field
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Senha"
                    type="password"
                    v-model="form.senha"
                    :rules="[rules.required, rules.counter]"
                ></v-text-field>
              </v-card-text>


              <Mensagem :mensagem="mensagemApi" v-show="mensagemApi"></Mensagem>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-3"
                       :loading="carregando"
                       type="submit"
                       dark>Entrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-main>
</template>

<script>
import api from "../api";
import Mensagem from "../components/Mensagem";

export default {
  name: "TelaLogin",
  components: {
    Mensagem
  },
  data() {
    return {
      carregando: false,
      mensagemApi: null,
      form: {
        email: '',
        senha: ''
      },
      rules: {
        required: value => !!value || 'Obrigatório.',
        counter: value => value.length >= 8 || 'Mínimo de 8 caracteres',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'E-mail inválido.'
        },
      }
    }
  },
  methods: {
    logar() {
      this.carregando = true;

      api.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`)
          .then(() => {

            api.post('login', this.form)
                .then((r) => {
                  this.$store.commit("ATUALIZAR_USUARIO_NOME", r.data.nome);
                  this.$store.commit("EXIBIR_BARRA", true);
                  // api.defaults.headers.common = {'Authorization': `Bearer ${r.data.token}`};

                  // window.location = '/home';
                  this.$router.push('/consulta-ocorrencia');
                })
                .catch((r) => {
                  if (r.response) {
                    this.mensagemApi = r.response.data;
                  }
                })
                .finally(() => {
                  this.carregando = false;
                });

          });


    }
  },
  created() {
    this.$store.commit("EXIBIR_BARRA", false);
  }
}
</script>

<style scoped>

</style>