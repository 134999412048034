<template>
  <div class="mx-4 my-4">
    <v-form class="container" @submit.prevent="salvar">

      <v-card>
        <v-card-title>Atualização de senha</v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field label="Senha atual*" dense
                              type="password"
                              v-model="form.senhaAtual"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field label="Nova senha*" dense
                              type="password"
                              v-model="form.novaSenha"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field label="Confirmar Nova senha*" dense
                              type="password"
                              v-model="form.novaSenha_confirmation"></v-text-field>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <Mensagem :mensagem="mensagemApi" v-show="mensagemApi"></Mensagem>
        <v-divider></v-divider>

        <v-card-actions class="pa-6">
          <v-btn color="primary" type="submit" :loading="salvando">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import api from "../../api";
import Mensagem from "../../components/Mensagem";

export default {
  name: "TrocarSenha",
  components: {
    Mensagem
  },
  data() {
    return {
      salvando: false,
      mensagemApi: null,
      form: {
        senhaAtual: '',
        novaSenha: '',
        novaSenha_confirmation: '',
      }
    }
  },
  methods: {
    salvar() {
      this.salvando = true;
      api.post('/usuarios/atualizar-senha', this.form)
          .then(r => {
            this.mensagemApi = r.data;
          })
          .catch((r) => {
            if (r.response) {
              this.mensagemApi = r.response.data;
            }
          })
          .finally(() => {
            this.salvando = false;
          })
    }
  }
}
</script>

<style scoped>

</style>