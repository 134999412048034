<template>
  <div class="A4">

<pre>
--------------------------------------------------------------------------------
                      POLÍCIA JUDICIÁRIA DO ESTADO DO PARÁ
--------------------------------------------------------------------------------

BOLETIM DE OCORRENCIA POLICIAL : {{ bo.num_boletim }}         APRESENTAÇAO ? : {{ apresentacao }}
ADITAMENTO DO BOP.... : ({{ bo.cd_bop_adt }}) {{ bo.bop_adt }}
UNIDADE POLICIAL..... : ({{ bo.cd_unidade }}) {{ bo.ds_nome }}
AUTORIDADE POLICIAL.. : {{ bo.autoridade }}
REGISTRADOR DO BOP... : {{ bo.registrador }}
DATA/HORA DO REGISTRO : Em {{ bo.dt_registro }} às {{ bo.hs_registro }} Hs
IDENTIFICAÇÃO DO CASO : {{ bo.ds_caso }}

DADOS DO RELATOR................................................................
NOME................. : {{ bo.nm_pessoa }}
DOCUMENTO IDENTIDADE. : ({{ bo.doc_tipo }}) {{ bo.fd_doc_numero }}, {{ bo.fd_doc_orgao }}, {{ bo.fd_doc_uf }}
TELEFONE P/ CONTATO.. : ({{ bo.er_tel_ddd }}) {{ bo.er_tel_numero }}     TIPO RELATOR : {{ bo.relator_tipo }}
ENDEREÇO RESIDENCIAL. : {{ bo.rua_relator }}
         COMPLEMENTO. : {{ bo.er_complemento }}
    PERIMETRO/FUNDOS. : {{ bo.er_rua_desc }}
       BAIRRO/CIDADE. : {{ bo.bairro_relator }}/{{ bo.cidade_relator }}

DADOS DA OCORRÊNCIA.............................................................
ESPECIALIZAÇÃO DO CASO: {{ bo.espec_fato }}
DATA/HORA DO FATO.... : Em {{ bo.dt_fato }} às {{ bo.hs_fato }} Hs
LOCAL DA OCORRÊNCIA.. : {{ bo.ds_rua }}
         COMPLEMENTO. : {{ bo.en_oc_complemento }}
    PERIMETRO/FUNDOS. : {{ bo.en_oc_rua_desc }}
       BAIRRO/CIDADE. : {{ bo.ds_bairro }}/{{ bo.ds_cidade }}

RELATO DA OCORRÊNCIA............................................................

{{ bo.ds_texto }}

................................................................................


ESCRIVÃO: {{ bo.registrador }}     RELATOR: {{ bo.nm_pessoa }}


Em ____/_____/_____ as ____:____ hs               __________________________
                                                      AUTORIDADE POLICIAL

-------------------------------------------------------------------------------

</pre>
    <!-- Normalize or reset CSS with your favorite library -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/7.0.0/normalize.min.css">

    <!-- Load paper.css for happy printing -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.4.1/paper.css">
  </div>
</template>

<script>
import api from "../../api";

export default {
  name: "impressao",
  data() {
    return {
      bo: ''
    }
  },
  computed: {
    apresentacao() {
      return this.bo.fd_aprensentacao === "S" ? "SIM" : "NÃO"
    }
  },
  created() {
    this.$store.commit("EXIBIR_BARRA", false);
    api.get(`/ocorrencia/impresao/${this.$route.params.numero_bo}`)
        .then((r) => {
          this.bo = r.data;
        });
  }
}
</script>

<style scoped>
@page {
  size: A4;
}

pre {
  font-size: 15px;
  padding-left: 40px;
  margin-top: -60px;
  /*text-align: left*/
}
</style>