import Vue from 'vue'
import VueRouter from 'vue-router'
import TelaLogin from '../views/TelaLogin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: TelaLogin
  },
  {
    path: '/consulta-ocorrencia',
    component: () => import(/* webpackChunkName: "ocorrencia" */ '../views/ocorrencia/consultaOcorrencia'),
  },
  {
    path: '/ocorrencia/impressao/:numero_bo',
    name: 'impressao',
    component: () => import(/* webpackChunkName: "ocorrencia" */ '../views/ocorrencia/impressao'),
  },
  {
    path: '/usuarios/create',
    component: () => import(/* webpackChunkName: "usuarios" */ '../views/usuarios/create'),
  },
  {
    path: '/usuarios/senha',
    component: () => import(/* webpackChunkName: "usuarios" */ '../views/usuarios/senha'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
