<template>
  <v-alert
      class="ma-4"
      border="right"
      colored-border
      type="error"
      elevation="2">
    <ul v-show="existeMensagem">
      <li v-for="(m, index) in mensagemTratada" :key="index">{{ m }}</li>
    </ul>
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  name: "Mensagem",
  props: [
    'mensagem',
    'cor'
  ],
  data() {
    return {
      existeMensagem: false
    }
  },
  watch: {
    mensagemTratada(val) {
      this.existeMensagem = val.length > 0;
    }
  },
  computed: {
    mensagemTratada() {
      let mensagem = this.mensagem;

      if (mensagem === null) {
        return;
      }

      //testando se é uma resposta do axios
      if (typeof mensagem.response !== 'undefined') {
        mensagem = this.mensagem.response.data;//"data" guarda a resposta enviada pelo Laravel (estrutura padrão do axios)
      }

      let msgs = [];
      if (typeof mensagem.message === 'undefined') {
        return msgs;
      }

      if (typeof mensagem.errors === 'undefined') {
        msgs.push(this.mensagem.message);
        return msgs;
      }

      //erros enviados pela validação laravel
      let i;
      for (i in mensagem.errors) {
        msgs = msgs.concat(mensagem.errors[i]);
      }

      return msgs;
    }
  },
}
</script>

<style scoped>

</style>