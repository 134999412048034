<template>
  <div class="mx-4 my-4">
    <v-form class="container" @submit.prevent="salvar">

      <v-card>
        <v-card-title>Cadastro de Pessoa Física</v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          <v-container>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field label="Nome*" dense v-model="form.nome"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field label="Email*"
                              dense
                              v-model="form.email"
                              type="email"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field type="password"
                              label="Senha"
                              v-model="form.senha"
                              dense></v-text-field>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <Mensagem :mensagem="mensagemApi" v-show="mensagemApi"></Mensagem>
        <v-divider></v-divider>

        <v-card-actions class="pa-6">
          <v-btn color="primary" type="submit" :loading="salvando">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </div>
</template>

<script>
import api from "../../api";
import Mensagem from '@/components/Mensagem'

export default {
  name: "create",
  components: {
    Mensagem
  },
  data() {
    return {
      salvando: false,
      mensagemApi: null,
      form: {
        nome: '',
        email: '',
        senha: '',
      },
    }
  },
  methods: {
    salvar() {
      this.salvando = true;

      api.post('/usuarios', this.form)
          .then((r) => {
            this.mensagemApi = r.data;
          })
          .catch((r) => {

            if (r.response) {
              this.mensagemApi = r.response.data;
            }
          })
          .finally(this.salvando = false);
    }
  }
}
</script>

<style scoped>

</style>