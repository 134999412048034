import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//as constantes abaixo são truques para tornar mais simples recriar o estado inicial da sessão (quando usuário faz logout)
const getDefaultStateInstance = () => {//este método cria o modelo de sessão vazia
    return {
        usuario: {
            nome: '',
        },
        exibirBarra: false
    }
}

const state = getDefaultStateInstance();

export default new Vuex.Store({
    state,
    mutations: {
        /*
         * São convenções mutations:
         * - Estar em caixa alta
         * - Entre as palavras colocar _
         * - Apenas elas devem modificar um state. Actions não devem fazer isto.
         */
        ATUALIZAR_USUARIO_NOME(state, payload) {
            state.usuario.nome = payload;
        },
        EXIBIR_BARRA(state, payload) {
            state.exibirBarra = payload;
        },
        LOGOUT() {
            //chama o método que gera um modelo de sessão vazia (recriando o estado inicial)
            Object.assign(state, getDefaultStateInstance())
        }
    }
})
